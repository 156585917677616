import { BigNumber } from 'bignumber.js'
import TagManager from 'react-gtm-module'

import { GTM_ID } from '../config/settings'
import { TokenV3 } from '../model'
import { getMarketDisplayName } from '../utils'
import { store } from './reduxService'
import { pickTokenFrom, pickTokenTo } from './tokenService'

class GTMService {
  constructor() {
    const tagManagerArgs = {
      gtmId: GTM_ID,
    }

    // send analytics data only in production environment
    if (process.env.NODE_ENV === 'production' && window.location.origin === 'https://dex.guru') {
      TagManager.initialize(tagManagerArgs)
    }
  }

  public viewProductDetailsV3(currentToken: TokenV3, quoteToken: TokenV3): void {
    const productId = currentToken.id
    const productName = this.getProductName(currentToken, quoteToken)
    const tagManagerArgs = {
      dataLayer: {
        event: 'eec.detail',
        ecommerce: {
          detail: {
            actionField: {
              list: 'Token page',
            },
            products: [
              {
                id: productId,
                name: productName,
              },
            ],
          },
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  public viewProductDetailsV4(currentToken: TokenV3, quoteToken: TokenV3): void {
    const productId = currentToken.id
    const tokenFrom = this.getTokenFrom(currentToken, quoteToken)
    const productName = this.getProductName(currentToken, quoteToken)
    const tagManagerArgs = {
      dataLayer: {
        event: 'view_item',
        ecommerce: {
          items: [
            {
              item_id: productId,
              item_name: productName,
              item_brand: getMarketDisplayName(tokenFrom),
              quantity: '1',
            },
          ],
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  public addToCartV3(currentToken: TokenV3, quoteToken: TokenV3): void {
    const productId = currentToken.id
    const productName = this.getProductName(currentToken, quoteToken)
    const tagManagerArgs = {
      dataLayer: {
        event: 'eec.add',
        ecommerce: {
          add: {
            actionField: {
              list: 'Verify your token swap',
            },
            products: [
              {
                id: productId, // actual product id
                name: productName, // product name/title
                quantity: 1,
              },
            ],
          },
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }
  public addToCartV4(currentToken: TokenV3, quoteToken: TokenV3): void {
    const productId = currentToken.id
    const tokenFrom = this.getTokenFrom(currentToken, quoteToken)
    const productName = this.getProductName(currentToken, quoteToken)
    const tagManagerArgs = {
      dataLayer: {
        event: 'add_to_cart',
        ecommerce: {
          items: [
            {
              item_id: productId,
              item_name: productName,
              item_brand: getMarketDisplayName(tokenFrom),
              quantity: '1',
            },
          ],
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  public beginCheckoutV3(currentToken: TokenV3, quoteToken: TokenV3): void {
    const productId = currentToken.id
    const productName = this.getProductName(currentToken, quoteToken)
    const tagManagerArgs = {
      dataLayer: {
        event: 'eec.checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
            },
            products: [
              {
                id: productId, // actual product id
                name: productName, // product name/title
                quantity: 1,
              },
            ],
          },
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }
  public beginCheckoutV4(currentToken: TokenV3, quoteToken: TokenV3): void {
    const productId = currentToken.id
    const tokenFrom = this.getTokenFrom(currentToken, quoteToken)
    const productName = this.getProductName(currentToken, quoteToken)
    const tagManagerArgs = {
      dataLayer: {
        event: 'begin_checkout',
        ecommerce: {
          items: [
            {
              item_id: productId,
              item_name: productName,
              item_brand: getMarketDisplayName(tokenFrom),
              quantity: '1',
            },
          ],
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  public purchaseV3(
    txnId: string,
    tip: number,
    volumeUSD: BigNumber,
    currentToken: TokenV3,
    quoteToken: TokenV3
  ): void {
    const productId = currentToken.id
    const productName = this.getProductName(currentToken, quoteToken)
    const tagManagerArgs = {
      dataLayer: {
        event: 'eec.purchase',
        ecommerce: {
          currencyCode: 'USD',
          purchase: {
            actionField: {
              id: txnId,
              affiliation: currentToken.network.toUpperCase(),
              shipping: volumeUSD
                .times(tip / 100)
                .dp(2, BigNumber.ROUND_HALF_UP)
                .toFixed(), // $ Tips
            },
            products: [
              {
                id: productId,
                name: productName,
                quantity: 1,
                price: volumeUSD.dp(2, BigNumber.ROUND_HALF_UP).toFixed(),
              },
            ],
          },
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }
  public purchaseV4(
    txnId: string,
    tip: number,
    volumeUSD: BigNumber,
    currentToken: TokenV3,
    quoteToken: TokenV3
  ): void {
    const productId = currentToken.id
    const tokenFrom = this.getTokenFrom(currentToken, quoteToken)
    const productName = this.getProductName(currentToken, quoteToken)
    const tagManagerArgs = {
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          transaction_id: txnId, // actual order ID from the database
          currency: 'USD',
          value: volumeUSD.dp(2, BigNumber.ROUND_HALF_UP).toFixed(), // with two digits after the decimal point
          shipping: volumeUSD
            .times(tip / 100)
            .dp(2, BigNumber.ROUND_HALF_UP)
            .toFixed(), // $ Tips
          affiliation: currentToken.network.toUpperCase(), // wallet type or name, if available
          items: [
            {
              item_id: productId,
              item_name: productName,
              item_brand: getMarketDisplayName(tokenFrom),
              price: volumeUSD.dp(2, BigNumber.ROUND_HALF_UP).toFixed(),
              quantity: '1',
            },
          ],
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  public refundV3(txnId: string): void {
    const tagManagerArgs = {
      dataLayer: {
        event: 'eec.refund',
        ecommerce: {
          refund: {
            actionField: {
              id: txnId,
            },
          },
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }
  public refundV4(txnId: string): void {
    const tagManagerArgs = {
      dataLayer: {
        event: 'refund',
        ecommerce: {
          transaction_id: txnId,
        },
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }

  public getProductName = (currentToken: TokenV3, quoteToken: TokenV3): string => {
    const { activeTradeType } = store.getState()
    const tokenFrom = pickTokenFrom(activeTradeType, { quoteToken, currentToken })
    const tokenTo = pickTokenTo(activeTradeType, { quoteToken, currentToken })
    return `${getMarketDisplayName(tokenFrom)}-${getMarketDisplayName(tokenTo)}`
  }

  private getTokenFrom = (currentToken: TokenV3, quoteToken: TokenV3): TokenV3 => {
    const { activeTradeType } = store.getState()
    return pickTokenFrom(activeTradeType, { quoteToken, currentToken })
  }

  // events for GA3
  public v3 = {
    viewProductDetails: this.viewProductDetailsV3.bind(this),
    addToCart: this.addToCartV3.bind(this),
    beginCheckout: this.beginCheckoutV3.bind(this),
    purchase: this.purchaseV3.bind(this),
    refund: this.refundV3.bind(this),
  }

  // events for GA4
  public v4 = {
    viewProductDetails: this.viewProductDetailsV4.bind(this),
    addToCart: this.addToCartV4.bind(this),
    beginCheckout: this.beginCheckoutV4.bind(this),
    purchase: this.purchaseV4.bind(this),
    refund: this.refundV4.bind(this),
  }
}

const gtmService = new GTMService()

export default gtmService
