import { defaultPeriodSidebarChart } from '../config/settings'
import { getChartTimestamps } from '../helpers/chartHelpers'
import { AMMModel, ChartDataV3, ChartRowCategoryV3, TokenId } from '../model'
import apiClient from './ApiClient'

const getChartRequestParams = (
  period: number,
  selectedCurrency: string
): { beginTimestamp: number; endTimestamp: number; currency: string } => {
  const { beginTimestamp, endTimestamp } = getChartTimestamps({ period })
  const currency = !selectedCurrency || selectedCurrency === 'USD' ? 'USD' : 'N'
  return { beginTimestamp, endTimestamp, currency }
}
export const loadChartsData = async (
  selectedCurrency: string,
  currentTokenId: TokenId,
  period: number = defaultPeriodSidebarChart,
  amms = ''
): Promise<ChartDataV3> => {
  // amms here returning every amm history besides summary(all), if its set to '',
  // otherwise specific comma-separated amms. Use amms='all' for dashboard sidebars
  // to get only summary documents, not splited into separate amms.

  if (!selectedCurrency || !currentTokenId) {
    return []
  }

  try {
    const { beginTimestamp, endTimestamp, currency } = getChartRequestParams(
      period,
      selectedCurrency
    )
    // should set interval = 1 hour for 24h (period = 1)
    const interval = period === 1 ? 3600 : undefined
    const response = await apiClient.tokens.loadTokenHistory({
      currentTokenId,
      beginTimestamp,
      endTimestamp,
      currency,
      amms,
      interval,
    })
    if (response.error) {
      // message in the catch section
      throw new Error(response.error?.message)
    }
    return response?.responseData?.data || []
  } catch (err) {
    console.error('Failed to load v3 ChartsData from history', err)
    return []
  }
}

export const loadChartsDataByTraderCategory = async (
  selectedCurrency: string,
  currentTokenId: TokenId,
  period: number = defaultPeriodSidebarChart
): Promise<ChartRowCategoryV3[]> => {
  if (!selectedCurrency || !currentTokenId) {
    return []
  }

  try {
    const { beginTimestamp, endTimestamp, currency } = getChartRequestParams(
      period,
      selectedCurrency
    )
    // should set interval = 1 hour for 24h (period = 1)
    const interval = period === 1 ? 3600 : undefined

    const response = await apiClient.tokens.loadTokenHistoryByTraderCategory({
      currentTokenId,
      beginTimestamp,
      endTimestamp,
      currency,
      interval,
    })
    if (response.error) {
      // message in the catch section
      throw new Error(response.error?.message)
    }

    return (response?.responseData?.data || []).map((item) => {
      return Object.fromEntries(Object.entries(item).map(([k, v]) => [k.toLowerCase(), v]))
    })
  } catch (err) {
    console.error('Failed to load v3 ChartsData from history', err)
    return []
  }
}

export const loadLastAMMs = async (tokenId?: TokenId): Promise<AMMModel[]> => {
  try {
    const response = await apiClient.tokens.loadLastAMMs(tokenId)
    if (response.error) {
      throw new Error(response.error.message)
    }
    return response?.responseData?.data || []
  } catch (err) {
    console.error(err)
    return []
  }
}
