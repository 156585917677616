import { auth } from '@telekomconsalting/dex-guru-internal-sdk'
import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import { BlockNumber } from 'web3-core'
import { Contract } from 'web3-eth-contract'

import erc20ABI from '../abi/erc20'
import { setNetworksConfig } from '../actions'
import { environment, RECALL_DELAY, RPC_ERR } from '../config/settings'
import { legacyApiFetch } from '../helpers/ProxyApiFetch'
import { Address, ChainStatus, NetworkConfigV3, TokenSpenders } from '../model'
import { retryWrapper } from '../utils'
import { sendApmError } from './apmService'
import { store } from './reduxService'
const networkConfigLoading = 'networkConfigLoading'

export const fetchChain = async (): Promise<void> => {
  const lsNetworks = localStorage.getItem('networksConfig')
  if (lsNetworks && lsNetworks !== 'undefined' && lsNetworks !== 'null') {
    sessionStorage.setItem(networkConfigLoading, 'true')
    store.dispatch(setNetworksConfig(JSON.parse(lsNetworks)))
  }

  const response = await legacyApiFetch<{ data: NetworkConfigV3[] }>(
    environment.getDexGuruAPIV3Url(),
    '/chain/'
  )
  const networksConfig: NetworkConfigV3[] = response?.data || []

  if (networksConfig) {
    localStorage.setItem('networksConfig', JSON.stringify(networksConfig))
    store.dispatch(setNetworksConfig(networksConfig))
  } else {
    throw new Error('Could not fetch chains')
  }

  sessionStorage.removeItem(networkConfigLoading)
}

export const fetchStatus = async (): Promise<ChainStatus[]> => {
  return (
    (
      await legacyApiFetch<{ data: ChainStatus[] }>(
        environment.getDexGuruAPIV2Url(),
        '/chain/status'
      )
    )?.data || []
  )
}

export const getReadonlyWeb3Instance = (network: string): Web3 | undefined => {
  const accessToken = auth.getAccessToken()
  if (!accessToken) {
    return
  }
  const readonlyRpcUrl = `${environment.getTradingAPIUrl()}/rpc/${network}`
  const httpProvider = new Web3.providers.HttpProvider(readonlyRpcUrl, {
    headers: [
      {
        name: 'Authorization',
        value: `Bearer ${accessToken}`,
      },
    ],
  })
  return new Web3(httpProvider)
}

export const getErc20Contract = (web3: Web3, tokenAddress: string): Contract => {
  return new web3.eth.Contract(erc20ABI, tokenAddress)
}

export const getBlockNumber = async (library: Web3, network: string): Promise<number> => {
  const readonlyWeb3 = getReadonlyWeb3Instance(network)
  const web3 = readonlyWeb3 || library
  return await web3.eth.getBlockNumber()
}

export const getBalance = async (
  library: Web3,
  network: string,
  account: string,
  blockNumber?: BlockNumber
): Promise<string> => {
  const readonlyWeb3 = getReadonlyWeb3Instance(network)
  const web3 = readonlyWeb3 || library

  const callBlockNumber = blockNumber || 'pending'
  return await web3.eth.getBalance(account, callBlockNumber)
}

export const getErc20Balance = async (
  library: Web3,
  network: string,
  account: string,
  tokenAddress: string,
  blockNumber?: BlockNumber
): Promise<string> => {
  const readonlyWeb3 = getReadonlyWeb3Instance(network)
  const web3 = readonlyWeb3 || library

  const callBlockNumber = blockNumber || 'pending'
  const erc20Contract = getErc20Contract(web3, tokenAddress)
  return await erc20Contract.methods.balanceOf(account).call(callBlockNumber)
}

export const getTokenAllowance = async (
  tokenAddress: string,
  spender: string,
  account: Address | null | undefined,
  network: string,
  library?: Web3,
  attempts = 3,
  canThrow = true
): Promise<BigNumber> => {
  try {
    const readonlyWeb3 = getReadonlyWeb3Instance(network)
    const web3 = readonlyWeb3 || library
    if (!web3) {
      throw new Error('web3 instance is not found')
    }
    const erc20Contract = getErc20Contract(web3, tokenAddress)
    const result = (await retryWrapper(
      erc20Contract.methods.allowance(account, spender).call,
      attempts,
      canThrow,
      RECALL_DELAY * 3
    )) as number | undefined
    return new BigNumber(result || 0)
  } catch (error: ReturnType<Error>) {
    console.error('allowance error ', error)
    sendApmError('token-allowance-error', `${error}`)
    throw new Error(RPC_ERR)
  }
}

export const isChainReadOnly = (networkConfig: NetworkConfigV3): boolean =>
  !networkConfig.zerox_api?.url && !networkConfig.one_inch_api?.url

export const getSpenderAddress = (
  spender: TokenSpenders,
  networkConfig: NetworkConfigV3
): string | null =>
  spender === TokenSpenders.zeroX
    ? networkConfig.zerox_api.spender_address
    : networkConfig.one_inch_api.spender_address
